import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/demo/node_modules/gatsby-theme-portfolio/src/components/common/Layout/index.jsx";
import { SEO } from "gatsby-theme-portfolio";
import { Intro } from "gatsby-theme-portfolio";
import { Skills } from "gatsby-theme-portfolio";
import { Contact } from "gatsby-theme-portfolio";
import { Footer } from "gatsby-theme-portfolio";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO mdxType="SEO" />
    <Intro mdxType="Intro" />
    <Contact mdxType="Contact" />
    <Skills mdxType="Skills" />
    <Footer mdxType="Footer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      